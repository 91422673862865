.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

}

.dropContent {
  padding: 48px;
  text-align: center;
  color: rgb(17, 25, 39);
  font-size: 36px;
  border: 1px dashed rgb(242, 244, 247);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  flex-wrap: wrap;
  width: 80%;
}

.dropContent:hover,
.dropContent:focus,
.dropContent:visited {
  cursor: pointer;
  opacity: 0.5;
  background-color: rgba(17, 25, 39, 0.04);
}

.uploadImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 50%;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  background-color: rgb(229, 231, 235);
  color: rgb(0, 0, 0);
  width: 64px;
  height: 64px;
}

.clickDescription,
.dropDescription {
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: rgb(17, 25, 39);
}

.descriptionUnderline {
  text-decoration: underline;
}

.description {
  margin-left: 16px;
}