.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.uploadContent {
  width: 80%;
  padding: 48px;
}

p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  color: rgb(17, 25, 39);
}

.buttonWrapper {
  display: flex;
  gap: 10px;
}

.cancelButton {
  border-radius: 12px;
  padding: 6px 16px;
  font-size: 20px;
  background-color: grey;
  border: 1px solid grey;
  color: white;
  cursor: pointer;
  transition-duration: 0.5s;
}

.cancelButton:hover {
  background-color: black;
}

.addButton {
  border-radius: 12px;
  padding: 6px 16px;
  font-size: 20px;
  background-color: rgb(99, 102, 241);
  border: 1px solid rgb(99, 102, 241);
  color: white;
  cursor: pointer;
  transition-duration: 0.5;
}

.addButton:hover {
  background-color: #5B27F7;
  border: 1px solid #5B27F7;
}